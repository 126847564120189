.servicesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 8vw;
  margin-bottom: 8vw;
  max-width: 100%;
  overflow: hidden;
}

.servicesHeader {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 3vw;
  font-weight: 650;
  color: #313131;
  padding: 10px;
  border-bottom: 5px solid #1c83c2;
  margin-bottom: 40px;
}

.servicesSectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.servicesSection {
  display: flex;
  flex-direction: column;
  width: calc(80% / 4);
  margin-left: 20px;
  margin-right: 20px;
}

.servicesSectionHeader {
  font-size: 18px;
  font-weight: 600;
  font-family: poppins;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: #dddddd solid 1px;
}

.servicesSectionContent {
  font-size: 16px;
  font-weight: 400;
  font-family: poppins;
  color: #666666;
  line-height: 2;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .servicesSectionContainer {
    display: flex;
    flex-direction: column;
  }

  .servicesSection {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .servicesSectionHeader {
    width: 100%;
  }
}

@media (max-width: 620px) {
  .servicesHeader {
    font-size: 36px;
  }
}
