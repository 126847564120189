.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10vw;
  width: 100%;
  overflow: hidden;
}

.aboutHeader {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 4.5vw;
  font-weight: 650;
  text-transform: uppercase;
  color: #313131;
  padding: 2vw;
}

.aboutContent {
  font-family: poppins;
  width: 90vw;
  font-size: 1.5vw;
  text-align: center;
  margin-bottom: 30px;
}

.aboutSloganContainer {
  margin-left: 30px;
  margin-right: 30px;
  letter-spacing: 2px;
  margin-top: 60px;
  margin-bottom: 50px;
  font-size: 50px;
  font-weight: 400;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #313131;
}

.aboutSlogan {
  text-align: center;
  margin-bottom: 10px;
}

.aboutSloganHighlight {
  color: #1c83c2;
  text-transform: uppercase;
}

.aboutBenefitsContainer {
  width: 90%;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
}

.aboutBenefitsSection {
  display: flex;
  flex-direction: row;
  width: 40vw;
  margin-bottom: 40px;
}

.aboutBenefitsImg {
  width: 70px;
  margin-bottom: 20px;
  color: #0f4e74;
  font-size: 24px;
  padding-right: 5px;
  padding-left: 5px;
}

.aboutBenefitsContentContainer {
  display: flex;
  flex-direction: column;
}

.aboutBenefitsHeader {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.aboutBenefitsContent {
  font-size: 14px;
  color: #666666;
  line-height: 2;
}

@media (max-width: 767px) {
  .aboutBenefitsSection {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }

  .aboutBenefitsSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
  }

  .aboutBenefitsContentContainer {
    justify-content: center;
    align-items: center;
  }

  .aboutBenefitsContent {
    text-align: center;
  }
}

@media (max-width: 620px) {
  .aboutHeader {
    font-size: 36px;
  }

  .aboutContent {
    font-size: 18px;
  }

  .aboutSlogan {
    font-size: 27px;
    margin-bottom: 5px;
  }
}
