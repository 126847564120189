.footerContainer {
  background-color: black;
  color: white;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
}

.footerInfo {
  display: flex;
  flex-direction: row;
  padding-bottom: 40px;
}

.socialMediaContainer {
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: center;
  padding: 15px;
}

.socialMediaContainer a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #414243;
  border-radius: 50%;
  margin: 20px;
  cursor: pointer;
  color: white;
}

.socialMediaContainer a:hover {
  border: 2px solid #414243;
}

.footerSectionContainer {
  padding: 15px;
  width: 20%;
}

.footerSectionContainer div {
  padding: 5px;
  cursor: pointer;
  font-size: 12px;
}

.footerSectionContainer span {
  margin-left: 5px;
}

.footerSectionContainer div {
  cursor: pointer;
}

.footerSectionContainer div:hover {
  font-weight: bold;
}

.seoTagContainer {
  width: 30%;
  padding: 15px;
}

.seoTagContainer div {
  width: 80%;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 1.5;
}

.copyrightContainer {
  font-size: 12px;
  padding: 20px;
  text-transform: uppercase;
}

@media (max-width: 620px) {
  .footerInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .socialMediaContainer {
    width: 90%;
  }

  .footerSectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  .seoTagContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  .copyrightContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
