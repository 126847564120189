.coverContainer {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #00020e;
  max-width: 100%;
  overflow: hidden;
}

.coverImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.3);
}

.coverTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
}

.mainContent {
  text-align: center;
  letter-spacing: -2px;
  font-weight: 800;
  font-size: 6vw;
  color: white;
}

.mainContentHighlighted {
  color: #1c83c2;
  text-transform: uppercase;
}

.subContent {
  font-family: Poppins;
  height: auto;
  width: auto;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: center;
  line-height: 24px;
  font-weight: 400;
  font-size: 2vw;
  margin-bottom: 1vw;
}

@media (max-width: 620px) {
  .coverTitle {
    width: 100%;
  }
  .mainContent {
    font-size: 36px;
  }

  .subContent {
    font-size: 12px;
  }
}
