.headerContainer {
  display: flex;
  align-items: center;
  height: 80px;
  background-color: white;
  border-bottom: 1px #1d2a38 solid;
  max-width: 100%;
  width: 100vw;
  padding-left: 30px;
  padding-right: 30px;
  position: fixed;
  top: 0;
  z-index: 99;
  overflow: hidden;
}

.headerLogoContainer {
  float: left;
  width: 15%;
  display: flex;
  align-items: center;
  height: 80px;
}

.headerLogo {
  max-width: 50px;
  min-width: 40px;
  min-height: 40px;
  cursor: pointer;
}

.headerSectionContainer {
  height: 100%;
  width: calc(100% - 30% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerSectionContainer div {
  height: 100%;
  display: flex;
  align-items: center;
  color: #a7a9ac;
  cursor: pointer;
  margin-left: 15px;
  margin-right: 15px;
}

.headerSectionContainer div:hover {
  color: black;
  border-bottom: 3px solid #1c83c2;
}

.headerSectionContainer div span {
  font-family: poppins;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.headerSection1024 {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 60px - 15%);
  font-size: 40px;
}

.headerSection1024 div {
  cursor: pointer;
}

.headerPage {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 999;
}

.headerPageIndex {
  margin-top: 80px;
}

.headerPageIndex li {
  font-size: 12px;
  font-weight: 600;
  color: #929292;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.headerPageIndex li:hover {
  color: white;
  background-color: black;
}

@media (max-width: 620px) {
  .headerContainer {
    height: 60px;
  }

  .headerLogoContainer img {
    max-width: 35px;
  }

  .headerSection1024 {
    font-size: 30px;
  }
}
